import './assets/styles/custom.scss';
import './App.css';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <>
      <AppRoutes/>
    </>
  );
}

export default App;
